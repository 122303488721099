import { Link } from "gatsby";
import * as React from "react"

type CardProps = {
    backgroundImage?: string;
    isGallery?: boolean;
    children?: any;
    centered?: boolean;
    to?: string;
    height?: string;
    backgroundPosition?: string;
}

const wrapDiv = (props: { children: any}) => {
    return (
        <div className="flex flex-wrap w-full xl:w-1/2">{props.children}</div>
    )
}

const wrapLink = (props: { children: any; to: string }) => {
    return (
        <Link to={props.to} className="flex flex-wrap w-full xl:w-1/2">{props.children}</Link>
    )
}

const wrapGallery = (props: { children: any; src: string }) => {
    return (
        <a href={props.src} className="spotlight flex flex-wrap w-full xl:w-1/2">{props.children}</a>
    )
}

export default (props: CardProps) => {
    const card = (
        <div
            className={`w-full bg-cover ${props.height ? props.height : 'h-96 2xl:h-[40em]'} bg-primary  rounded-lg text-secondary m-6 p-6 lg:m-8 lg:p-8 ${props.centered ? 'flex flex-col items-center justify-center text-center' : ''}`}
            style={props.backgroundImage
                ? { 
                    backgroundImage: `${props.children ? 'linear-gradient(0deg, rgba(0,0,0, 0.5), rgba(0,0,0, 0.3)),' : ''} url(${props.backgroundImage})`,
                    backgroundPosition: props.backgroundPosition || 'center top'
                }
                : undefined}
        >
            {props.children}
        </div>
    );

    if (props.to) {
        return wrapLink({ children: card, to: props.to })
    }

    if (props.isGallery && props.backgroundImage) {
        return wrapGallery({ children: card, src: props.backgroundImage });
    }

    return wrapDiv({ children: card });
}