import * as React from "react"
import { Helmet } from 'react-helmet'
import { PageProps } from "gatsby"
import Menu from "../components/Menu";

import p1 from "../images/projects/hm/p1.jpg" 

import GalleryImage from "../components/GalleryImage";
import Footer from "../components/Footer";
import Card from "../components/Card";

const Index: React.FC<PageProps> = () => {
  return (
    <div className="min-h-screen">
      {/* @ts-ignore */}
      <Helmet title="Elbae">
        <script src="https://rawcdn.githack.com/nextapps-de/spotlight/0.7.8/dist/spotlight.bundle.js"></script>
      </Helmet>
      
      <Menu />
      <main className="flex flex-wrap flex-row mt-24">
        <Card centered backgroundImage={p1} to="/projects/hm">
            <h1 className="font-superclarendon text-4xl mb-6">
                H&amp;M Flagshipstore Amsterdam
            </h1>
            <p>An amazing collaboration</p>
        </Card>
      </main>

      <Footer />
    </div>
  );
}

export default Index
